.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__skills-container {
  width: 80%;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: flex;
  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;
  margin: 1rem;
  transition: all 0.3s ease-in-out;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fff;

    img {
      width: 50%;
      height: 50%;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 900px) {
      width: 70px;
      height: 70px;
    }
  }
}